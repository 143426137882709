@import 'src/styles/_variables.scss';

.navContainer {
  flex-direction: row;
  justify-content: space-between;
  padding: 0;
  border-bottom: 1px  solid #58798F80;
  height: 48px;
  border-radius: 0;
  flex: 0 1 auto;
  .gotoIcon {
    padding-left: 10px;
    display: flex;
    align-items: center;
    gap: 10px;
  }
}

.iconGroup {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 14px;
  padding: 14px;
  align-items: center;
}

.supportLinks{
  display: flex;
}

.avatar-nav {
  padding-top: 20px;
  background: none;
  border: none;
  cursor: pointer;
}

.fluidOptions{
  padding-top: 30px;
}

@media (max-width: $screen-xs-max) {
  .sideNavToggle, .avatar-nav, .supportLinks ,.notificationsIcon  {
    display: none !important;
  }
}

.navIcon {
  display: flex;
  width: 24px;
  height: 24px;
  border-radius: 50%;
}

.avatarInfo {
  display: flex;
  padding: 6px 10px;
  gap: 7px;
}

@media (min-width: $screen-xs-max ) {
  .fluidOptions{
    display: none;
  }
}
  
.sideNavView{
  display: inline-flex;
  height: auto;
  z-index: 2;
  bottom: 0;
  top: 0;
  position: relative;
  @media screen and (max-width: $screen-tablet) {
    display: none;
  }
}