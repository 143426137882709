@import 'src/styles/_variables.scss';
@import './mixins';

.home-view{
  display: flex;
  flex-flow: column;
  height: calc(100vh - 46px);
}

.subscription-card {
  border-width : 8px 1px 1px;
  border-style: solid;
  border-radius: 8px;
  // 3 cards per row if content page width is > 800px, else 2 cards per row
  grid-column: span 4;//props.selectedPageWidth > 800 ? 'span 4' : 'span 6',
  width: 100%;
  display: grid;
  align-content: space-between;
  &.g2a {
    border-color: #22979E var(--goto-border-color-default) var(--goto-border-color-default); ;
  }
  &.g2asd {
    border-color: #22979E var(--goto-border-color-default) var(--goto-border-color-default); ;
  }
  &.g2ars {
    border-color: #22979E var(--goto-border-color-default) var(--goto-border-color-default); ;
  }
  &.g2aseeit {
      border-color: #22979E var(--goto-border-color-default) var(--goto-border-color-default); ;
  }
  &.g2m{
  border-color : #07CB73 var(--goto-border-color-default) var(--goto-border-color-default);
  }
  &.g2t {
    border-color: #07CB73 var(--goto-border-color-default) var(--goto-border-color-default); ;
  }
  &.g2w {
    border-color: #07CB73 var(--goto-border-color-default) var(--goto-border-color-default); ;
  }
  &.openvoice {
    border-color: #5C61A6 var(--goto-border-color-default) var(--goto-border-color-default); ;
  }
  &.hvoice {
    border-color: #5C61A6 var(--goto-border-color-default) var(--goto-border-color-default); ;
  }
  &.grasshopper {
    border-color: #48AE35 var(--goto-border-color-default) var(--goto-border-color-default);
  }
  &.g2p {
    border-color: #188838 var(--goto-border-color-default) var(--goto-border-color-default);
  }
  &.jive{
    border-color:#C4D600 var(--goto-border-color-default) var(--goto-border-color-default);
  }
  &.gotoresolve{
    border-color: #9254FF var(--goto-border-color-default) var(--goto-border-color-default);
  }
  &.partner {
    border-color: #2C72DE var(--goto-border-color-default) var(--goto-border-color-default);
  }
  &.gotoconnect {
    border-color: #07CB73 var(--goto-border-color-default) var(--goto-border-color-default); ;
  } 
}

.subscription-card__header {
  padding: 1.25rem 1rem;
  height: 15rem;
  //display: grid;
  align-content: space-between;
}

.subscription-card__badge-icon {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
}

.subscription-card__product-icon {
  display: flex;
  flex-direction: row;
  column-gap: 8px;
  grid-column: 2/3;
  justify-self: center;
}

.subscription-card__product-name{
  display: flex;
  justify-content: center;
  gap: 5px;
}
.subscription-card__product-name::part(text) {
  max-height: 3rem;
  line-height: 1.5rem !important;
  overflow: hidden;
}


.prodLogos__subProd{
  display: flex;
  justify-content: center;
}

.subscription-card_buttons {
  display: flex;
  align-items: center;
  flex-direction: column;
  padding-top: 20px;
}

.subscription-card_link-text-button {
  padding-top: 5px;
}

.addlicense {
  display: flex;
  align-items:baseline;
  justify-content:center;
}

.all-subscriptions__tiles-wrapper{
  padding: 30px;
  @media (max-width: 767px) {
    padding: 0px 15px 15px 15px;
  }
}

@media (min-width: 1000px) {
  .productContainer{
    grid-template-columns:repeat(2, 1fr); 
  }
}
.productContainer {
  padding-top: 15px;
  margin-top: .5rem;
  display: grid;
  grid-gap: 2rem;
  gap: 2rem;
  @media screen and (min-width: 1001px) {
    grid-template-columns: repeat(12, 1fr);
  }
  @media (max-width: 1000px) {
     grid-template-columns: repeat(8, 1fr); 
  }
  @media (max-width: $screen-tablet) {
    grid-template-columns: repeat(4, 1fr); 
 }
}


.productContainer_noproductsLogos{
  display: flex;
  padding-top: 25px;
  padding-bottom: 25px;
  gap: 20px;
}
.noproductsLogos{
  height: 50px;
  width: 50px;
}
.noproducts__message {
 padding-top: 20px;
}

.quick-links-wrapper{
  padding:0px 30px 30px 30px;
  @media (max-width: 767px) {
    padding: 0px 15px 15px 15px;
  }
}

.addproduct{
  padding-top: 20px;
}