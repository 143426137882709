@import '../../styles/variables';

html {
  overflow: hidden;
}

body {
  margin: 0;
  color: #25282D !important;
}

.bannerDisplay{
  display: flex;
  flex-direction: column;
  flex: 0 0 auto;
}

.mainDisplay {
  height: calc(-46px + 100vh);
  position: relative;
  display: flex;
  min-width: -webkit-fill-available;
}

@media (max-width: $screen-tablet) {
  .mainDisplay {
    overflow-y: auto;
    overflow-x: clip;
    display: inline-block;
  }
}

.fluidAppContainer {
  display: flex;
  flex: 1 ;
  overflow-y: hidden;
  overflow-x: hidden;
}

@media (max-width: $screen-tablet) {
  .prodContainer {
    //padding: 16px;
    overflow-y: auto;
    overflow-x: clip;
    display: inline-block;
    height: auto;
  }
}
.prodContainer{
  display: grid;
  overflow: auto;
  height: calc(-46px + 100vh);
}

