$green: #01B17B;
$dark-green: #009F6E;
$mid-gray: #DEDEDE;
$light-gray: #DADDE0;
$lighter-gray: #E2E2E2;
$lightest-gray: #EEE;
$gray: #8997AD;
$blue: #319EDD;
$dark-blue: #147eb9;
$light-blue: #EAF6FC;
$dark: #434B58;
$red: #ce0c25;
$error-red: #E02D52;
$body-text_color: #767676;
$link-color: #006aff;
$link-hover-color: #479ffe;
$common-border: #58798F80;
$default-text-color: #000000;


$color-txt: #444444;
$color-primary: #479FFE;
$color-secondary: #1678E3;
$color-focus: #1C72A4;
$color-accent: #16D8A0;
$color-leftcol-bg: #E0E3E7;
$color-footer: #081B31;
$color-footer-txt: #6F7E91;
$color-gray-lightest: #edeef0;
$color-dark-gray: #B5B4B5;
$color-white: #FFFFFF;

$font-normal: Lato-Regular;
$font-bold: Lato-Bold;
$font-light: Lato-Light;
// http://getbootstrap.com/css/#grid
$screen-xxs-max: 320px; // older mobile phones
$screen-xs-max: 767px; // mobile phone
$screen-sm-max: 991px; // tablet
$screen-md-max: 1199px; // laptop
$screen-tablet: 768px;
$screen-desktop: 991px;
$font-xs: 11px;
