@import './variables';

.loader-background {
  fill: none;
  stroke-width: 2px;
  stroke: #f7f7f7;
}

.loader-container {
    width: 32px;
    display: flex;
    margin: auto;
    height: 100vh;
    align-items: center;
}

.loader {
  fill: none;
  stroke: #2c72de;
  stroke-width: 2px;
  stroke-dashoffset: 10;
  stroke-dasharray: 20;
  transform-origin: 50% 50% 0px;
  transform: rotate(-90deg);
  -webkit-animation:spin-infinite 0.7s linear infinite;
  -moz-animation: spin-infinite 0.7s linear infinite;
  -o-animation: spin-infinite 0.7s linear infinite;
  -ms-transition: spin-infinite 0.7s linear infinite;
  transition: spin-infinite 0.7s linear infinite;
  animation: spin-infinite 0.7s linear infinite;
}

hr {
  border-top-color: $mid-gray;
}

.learnMoreLink {
  color: $default-text-color !important;
  text-decoration: underline !important;
  text-underline-offset: 2px;
  margin-left: 5px;
}

.regularText {
  font-family: lato-regular, "Helvetica Neue", Helvetica, Arial, sans-serif;
}

hr {
  margin: 0;
}


.app-container {
  min-height: 100%;
}

.app-container-inner {
  display: flex;
  flex-flow: column;
  height: 100vh;
  min-height: 100%;
}

@media (max-width: $screen-xs-max) {
  .app-container-inner {
    margin-bottom: -58px
  }
}

.coloredTextForStrength {
  color: #1B8756;
  font-family: lato-bold, "Helvetica Neue", Helvetica, Arial, sans-serif;
}
