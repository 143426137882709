@import '../styles/variables';

.alertContainer {
  text-align: center;

  .bannerMessageIcon {
    font-size: 24px;
  }

  .secondPartOfText {
    margin-left: 6px;
  }

  .bannerMessageCTA {
    margin-right: 0;
    border: 1px solid rgb(255, 255, 255);
  }
}

.content {
  display: flex;
  align-items: center;
  justify-content: center;
}

