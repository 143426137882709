@import 'src/styles/_variables.scss';
@media (max-width: $screen-tablet) {
    .gotoBanner{
        display: block;
    }
}

.gotoBannerIcon {
    display: flex;
    gap: 10px;
}