@import 'src/styles/_variables.scss';
@media (min-width: 1000px) {
  .productContainer{
    grid-template-columns:repeat(2, 1fr); 
  }
}

.productContainer {
  padding: 15px 0px 25px 0px;
  margin-top: .5rem;
  display: grid;
  grid-gap: 2rem;
  gap: 2rem;
  @media screen and (min-width: 1001px) {
    grid-template-columns: repeat(12, 1fr);
  }
  @media (max-width: 1000px) {
      grid-template-columns: repeat(8, 1fr); 
  }
  @media (max-width: $screen-tablet) {
    grid-template-columns: repeat(4, 1fr); 
  }
}

.quick-link-card {
  position: relative;
  border-color : rgba(171, 188, 199, 1) var(--goto-border-color-default) var(--goto-border-color-default);
  border-width : 8px 1px 1px;
  border-style: solid;
  border-radius: 8px;
  // 3 cards per row if content page width is > 800px, else 2 cards per row
  grid-column: span 4;
  width: 100%;
  display: grid;
  align-content: space-between;
}

.quickLinkHeader {
  padding: 1.25rem 1rem;
  height: 15rem;
}

.quickLinkBadgeIcon{
  display: block;
  padding: 15px;
}

.quickLinkCardIcon {
  display: flex;
  flex-direction: row;
  column-gap: 8px;
  grid-column: 2/3;
  justify-self: center;
}

.quickLinkContent{
  padding-left: 15px;
}

.quick-card-link{
  display: block;
  position: absolute;
  bottom: 10px;
  float: right;
  right: 10px;
  unicode-bidi: isolate;
  float: right;
}
