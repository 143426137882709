.notificationCardHeader {
  display: flex;
  align-items: center;
  gap: 5px;
}

.notificationCardButton {
  display: flex;
  padding-top: 5px;
  padding-bottom: 20px;
}


