@media screen and (min-width: 768px) {
	.notification__content {
		padding: 0px 15px 15px 15px !important;
	}
}

.notification__content {
  padding: 0px 25px !important;
}

.gridContainer {
	padding-bottom: 15px;
	align-items: center; 
	justify-content: space-between;
}

.gridContainerNew {
	padding-left: 15px
}
.gridContainerClearAll {
	padding-right: 15px
}

.noNewNotifications {
	display:flex;
	position:absolute;
	align-items: center; 
}