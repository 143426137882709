.drawer {
  width: 390px;
  flex-shrink: 0;
  @media ( max-width: 767px){
    width: 100%,
  };
  
}

.drawerPaper {
  position: absolute !important;
  width: 330px;
  background-color: var(--goto-ui-01) !important;
  overflow: visible;
  
  @media ( max-width: 767px) {
    width: 100%,
  };
  
}

.closeButton {
  position: relative;
  top: -4px;
}

.drawerHeader {
  display: flex;
  flex-direction: column;
  padding: 16px;
}

.drawerPaperAnchorDockedRight {
  border-color: var(--goto-field-01),
}